// Generated by Framer (4025f14)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bZG1PGeRI"];

const variantClassNames = {bZG1PGeRI: "framer-v-mjh22p"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "bZG1PGeRI", image: CYs1eckCp = {src: new URL("assets/luqL7W97YtP5c4Ek4ylJros4hfc.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bZG1PGeRI", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vBEd4", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 256, intrinsicWidth: 256, pixelHeight: 256, pixelWidth: 256, ...toResponsiveImage(CYs1eckCp)}} className={cx("framer-mjh22p", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bZG1PGeRI"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vBEd4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vBEd4 * { box-sizing: border-box; }", ".framer-vBEd4 .framer-19ykzi9 { display: block; }", ".framer-vBEd4 .framer-mjh22p { height: 256px; overflow: visible; position: relative; width: 256px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"CYs1eckCp":"image"}
 */
const Framerq_tM3V7Wd: React.ComponentType<Props> = withCSS(Component, css, "framer-vBEd4") as typeof Component;
export default Framerq_tM3V7Wd;

Framerq_tM3V7Wd.displayName = "highlight";

Framerq_tM3V7Wd.defaultProps = {height: 256, width: 256};

addPropertyControls(Framerq_tM3V7Wd, {CYs1eckCp: {__defaultAssetReference: "data:framer/asset-reference,luqL7W97YtP5c4Ek4ylJros4hfc.png?originalFilename=highlight.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerq_tM3V7Wd, [])